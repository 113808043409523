import React, { useEffect } from 'react';
import SEO from '../components/seo';
import urls from '../constants/urls';

function Partners ({ location }) {    
    return (        
        <React.Fragment>
            <SEO title="Our Partners - Mike" url={location.pathname} />
            <div className="partners-sec">
                <div className="container">
                    <h1 className="">
                        OUR PARTNERS
                    </h1>
                    <div className="partners-list">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="partners-item">
                                    <div className="partners-item__header">
                                        <a href={`${urls.BASE_URL}/go/rotorvideos`} className="partner-logo" target="_blank">
                                            <img src={require('../images/rotor-logo.svg')} alt="Rotor Logo" />
                                        </a>
                                        <a href={`${urls.BASE_URL}/go/rotorvideos`} target="_blank">   
                                            <h2 className="partners__name">
                                                Rotor
                                            </h2>
                                        </a>
                                    </div>                            
                                    <span className="partners__subtitle">
                                        Make music videos
                                    </span>
                                    <span className="partners__description">
                                        <a href={`${urls.BASE_URL}/go/rotorvideos`} target="_blank">Rotor</a> enables you to make music and lyric videos in just minutes. Upload your music, select from their vast library of visual clips, choose from the selection of edit styles designed by music video directors and visual artists, and the application will analyse your music and selected clips and generate a professional video for you in minutes. <br />You pay a flat fee once you're ready to download the finished video which is $19 or less, and then the video is 100% yours to use as you wish. <a href={`${urls.BASE_URL}/go/rotorvideos`} target="_blank">Sign up with this link</a> to get 10% off.
                                    </span>
                                </div>
                            </div>
                            {/*<div className="col-lg-6">
                                <div className="partners-item">
                                    <div className="partners-item__header">
                                        <a href={`${urls.BASE_URL}/go/landr`} className="partner-logo" target="_blank">
                                            <svg width="75px" height="60px" viewBox="0 0 78 60" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>LANDR Logo</title>
                                                <g>
                                                    <g>
                                                        <g>
                                                            <path fill="#F9FAFB" d="M30.5,61c-16.5,0-30-13.5-30-30S14,1,30.5,1c16.5,0,30,13.5,30,30S47.1,61,30.5,61z M30.5,2.5 C14.8,2.5,2,15.3,2,31c0,15.7,12.8,28.5,28.5,28.5S59,46.7,59,31C59,15.3,46.3,2.5,30.5,2.5z" />
                                                        </g>
                                                        <g>
                                                            <path fill="#F9FAFB" d="M45.4,61c-16.5,0-30-13.5-30-30s13.5-30,30-30s30,13.5,30,30S62,61,45.4,61z M45.4,2.5 C29.7,2.5,16.9,15.3,16.9,31c0,15.7,12.8,28.5,28.5,28.5c15.7,0,28.5-12.8,28.5-28.5C73.9,15.3,61.1,2.5,45.4,2.5z" />
                                                        </g>
                                                    </g>
                                                    <path fill="#F9FAFB" d="M27.8,35.2L27,33.3h-3.7l-0.8,1.9H21l3.5-8.4h1.1l3.5,8.4H27.8z M26.5,32.1l-1.4-3.3l-1.4,3.3H26.5z" />
                                                    <path fill="#F9FAFB" d="M40.6,35.2l-4.1-5.9v5.9h-1.3v-8.4h1.1l4.1,5.9v-5.9h1.3v8.4H40.6z" />
                                                    <path fill="#F9FAFB" d="M51.6,26.8c2.3,0,4.1,1.5,4.1,4.2c0,2.6-1.8,4.2-4.1,4.2h-3.3v-8.4H51.6z M49.6,34h2c1.6,0,2.8-1,2.8-3 c0-2-1.1-3-2.7-3h-2V34z" />
                                                    <g>
                                                        <path fill="#F9FAFB" d="M12.5,34v1.2H7.2v-8.4h1.3V34H12.5z" />
                                                        <path fill="#F9FAFB" d="M67.2,32h-1.8v3.2h-1.3v-8.4h3.4c1.5,0,2.6,0.9,2.6,2.6c0,1.2-0.6,2-1.4,2.4l1.9,3.4H69L67.2,32z M67.3,30.8 c0.8,0,1.4-0.5,1.4-1.4c0-0.9-0.6-1.4-1.4-1.4h-1.9v2.8H67.3z" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </a>
                                        <a href={`${urls.BASE_URL}/go/landr`} target="_blank">
                                            <h2 className="partners__name">
                                                LANDR
                                            </h2>
                                        </a>
                                    </div>
                                    <span className="partners__subtitle">
                                        Creative tools for musicians. 
                                    </span>
                                    <span className="partners__description">
                                        <a href={`${urls.BASE_URL}/go/landr`} target="_blank">LANDR</a> enables you to instantly master your tracks without the expense of studio rates or complex plug-ins. Using AI and machine learning, their software replicates the process of human engineers to give high quality results. <a href={`${urls.BASE_URL}/go/landr`} target="_blank">Find out more</a> about how to get started. <br />They also have a collection of <a href={`${urls.BASE_URL}/go/landrsamples`} target="_blank">royalty-free samples</a> available for use with smart browsing to find the sound you are looking for.
                                    </span>
                                </div>
                            </div>*/}
                            <div className="col-lg-6">
                                <div className="partners-item">
                                    <div className="partners-item__header">
                                        <a href={`${urls.BASE_URL}/go/indiebible`} className="partner-logo">
                                            <img src={require('../images/indie-bible-directories.png')} alt="Indie Bible logo" />
                                        </a>
                                    </div>
                                    <span className="partners__subtitle">
                                        The ultimate resource for independent artists
                                    </span>
                                    <span className="partners__description">
                                        <a href={`${urls.BASE_URL}/go/indiebible`} target="_blank">The Indie Bible</a> is an e-book with contact details for over 7,000 radio stations, music blogs, and music magazines where you can get exposure for your music. They also offer the Indie Spotify Bible - with contact details for over 4,200 Spotify playlists and the Indie YouTube Bible - with contact details for over 3,400 YouTube playlists. <a href={`${urls.BASE_URL}/go/indiebible`}>Get access to the contacts</a>.                            
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="partners-item">
                                    <div className="partners-item__header">
                                        <a href={`${urls.BASE_URL}/go/radioairplay`} className="partner-logo" target="_blank">
                                            <img src={require('../images/radioarplay.png')} alt="RadioAirplay logo" />
                                        </a>
                                    </div>
                                    <span className="partners__subtitle">
                                        Get guaranteed airplay on Internet Radio.
                                    </span>
                                    <span className="partners__description">
                                        <a href={`${urls.BASE_URL}/go/radioairplay`} target="_blank">RadioAirplay</a> helps you to get your music heard by offering plays to internet radio listeners who like your style of music. <a href={`${urls.BASE_URL}/go/radioairplay`} target="_blank">Sign up here</a> to get 100 free plays.
                                    </span>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="partners-item">
                                    <div className="partners-item__header">
                                        <a href={`${urls.BASE_URL}/go/fiverr`} className="partner-logo" target="_blank">
                                            <svg width="89" height="27" viewBox="0 0 89 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g id="fiverr_logo_nav" fill="#fff">
                                                    <path d="m81.6 13.1h-3.1c-2 0-3.1 1.5-3.1 4.1v9.3h-6v-13.4h-2.5c-2 0-3.1 1.5-3.1 4.1v9.3h-6v-18.4h6v2.8c1-2.2 2.3-2.8 4.3-2.8h7.3v2.8c1-2.2 2.3-2.8 4.3-2.8h2zm-25.2 5.6h-12.4c.3 2.1 1.6 3.2 3.7 3.2 1.6 0 2.7-.7 3.1-1.8l5.3 1.5c-1.3 3.2-4.5 5.1-8.4 5.1-6.5 0-9.5-5.1-9.5-9.5 0-4.3 2.6-9.4 9.1-9.4 6.9 0 9.2 5.2 9.2 9.1 0 .9 0 1.4-.1 1.8zm-5.7-3.5c-.1-1.6-1.3-3-3.3-3-1.9 0-3 .8-3.4 3zm-22.9 11.3h5.2l6.6-18.3h-6l-3.2 10.7-3.2-10.8h-6zm-24.4 0h5.9v-13.4h5.7v13.4h5.9v-18.4h-11.6v-1.1c0-1.2.9-2 2.2-2h3.5v-5h-4.4c-4.3 0-7.2 2.7-7.2 6.6v1.5h-3.4v5h3.4z" />
                                                </g>
                                                <g id="fiverr_logo_nav_dot" fill="#1dbf73">
                                                    <path d="m85.3 27c2 0 3.7-1.7 3.7-3.7s-1.7-3.7-3.7-3.7-3.7 1.7-3.7 3.7 1.7 3.7 3.7 3.7z" />
                                                </g>
                                            </svg>
                                        </a>                            
                                    </div>
                                    <span className="partners__subtitle">
                                        Music promotion services
                                    </span>
                                    <span className="partners__description">
                                        <a href={`${urls.BASE_URL}/go/fiverr`} target="_blank">Fiverr</a> enables you to buy music promotion services from their marketplace. Services on offer include promoting your music via blogs and social media accounts, creating album artwork, reviewing your music, writing bios and press releases, and making music videos. <a href={`${urls.BASE_URL}/go/fiverr`} target="_blank">Browse the services available</a>.
                                    </span>
                                </div>
                            </div>
                        <div className="col-lg-6">
                                <div className="partners-item">
                                    <div className="partners-item__header">
                                        <a href={`${urls.BASE_URL}/go/distrokid`} className="partner-logo" target="_blank">
                                            <img src={require('../images/distrokid.png')} alt="Distrokid logo" />
                                        </a>
                                    </div>
                                    <span className="partners__subtitle">
                                        Upload and sell your music on iTunes, Spotify, Amazon, and Google Play.
                                    </span>
                                    <span className="partners__description">
                                        <a href={`${urls.BASE_URL}/go/distrokid`} target="_blank">Distrokid</a> enables you to get your music into Spotify, iTunes, Amazon, Tidal, TikTok, YouTube, and more. For a flat rate of $19.99/year, you get unlimited uploads and keep 100% of your earnings. <a href={`${urls.BASE_URL}/go/distrokid`} target="_blank">Get started</a> with publishing your music.
                                    </span>
                                </div>
                            </div>
                        </div>   
                    </div>     
                </div>
            </div>
        </React.Fragment>
    );
}

export default Partners;